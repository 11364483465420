<template>
   <div>
      <Header />
      <Generales />
      <Footer />
   </div>
</template>

<script>
import Generales from "../components/Generales/Generales.vue";
export default {
   components: {
      Generales,
   },
};
</script>

<style scoped></style>
