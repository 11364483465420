<template>
   <div class="ma-6">
      <Notification type="error" message="Error" ref="error_noti" />
      <Notification type="success" message="Información actualizada" ref="ok_noti" />
      <Dialog message="¿Desea enviar los posiciones generales?" @result_ok="send()" ref="send" />
      <v-data-table :loading="isloading" :headers="headers" :items="items" :items-per-page="10" class="elevation-1 mb-8" :search="search">
         <template v-slot:top>
            <v-toolbar flat color="secondary">
               <v-btn @click="toExcel()"><v-icon left>mdi-microsoft-excel</v-icon>Exportar</v-btn>
               <v-btn @click="initialize" icon small class="ml-2"><v-icon small>mdi-autorenew</v-icon></v-btn>
               <v-spacer></v-spacer>
               <v-text-field v-model="search" label="Search" clearable hide-details></v-text-field>
            </v-toolbar>
            <v-toolbar flat color="primary">
               <v-select
                  class="my-select ma-2"
                  outlined
                  hide-details=""
                  dense
                  v-model="rango"
                  label="Rango"
                  :items="rangos"
                  item-text="text"
                  item-value="value"
               ></v-select>
               <v-select
                  class="my-select ma-2"
                  outlined
                  hide-details=""
                  dense
                  v-model="categoria"
                  label="Categoria"
                  :items="categorias"
                  item-text="text"
                  item-value="value"
               ></v-select>
               <v-spacer></v-spacer>
               <v-btn v-if="rango && categoria" @click="$refs.send.Show()"><v-icon left>mdi-send</v-icon>Enviar mensajes</v-btn>
            </v-toolbar>
         </template>
         <template v-slot:[`item.rango`]="{ item }">
            <div class="pretty_title" v-if="item.rango == 'R'">RX</div>
            <div class="pretty_title" v-else-if="item.rango == 'A'">Avanzado</div>
            <div class="pretty_title" v-else-if="item.rango == 'M'">Master</div>
            <div class="pretty_title" v-else-if="item.rango == 'I'">Intermedio</div>
            <div class="pretty_title" v-else-if="item.rango == 'K'">Kids</div>
            <div class="pretty_title" v-else>Principiante</div>
         </template>
         <template v-slot:[`item.score`]="{ item }">
            <div class="pretty_title_2">{{item.score}}</div>
         </template>
         <template v-slot:[`item.categoria`]="{ item }">
            <v-icon small color="pink" v-if="item.categoria == 'F'">mdi-face-woman</v-icon>
            <v-icon small color="blue" v-else>mdi-face-man</v-icon>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import fileDownload from "js-file-download";

function convertToCSV(arr) {
   const array = [Object.keys(arr[0])].concat(arr);
   return array
      .map((it) => {
         return Object.values(it)
            .map((el) => {
               return el.toString().split(",").join("");
            })
            .toString();
      })
      .join("\n");
}

export default {
   data() {
      return {
         search: "",
         isloading: true,
         headers: [
            { text: "Posición", value: "posicion" },
            { text: "Atleta", value: "nombre" },
            { text: "Categoria", value: "categoria" },
            { text: "Rango", value: "rango" },
            { text: "Gym", value: "gym" },
            { text: "Score", value: "score" },
         ],
         items: [],
         categoria: null,
         rango: null,
         categorias: [
            {
               value: "F",
               text: "Femenino",
            },
            {
               value: "V",
               text: "Varonil",
            },
         ],
         rangos: [
            {
               value: "P",
               text: "Principiante",
            },
            {
               value: "I",
               text: "Intermedio",
            },
            {
               value: "M",
               text: "Master",
            },
            {
               value: "A",
               text: "Avanzado",
            },
            {
               value: "R",
               text: "RX",
            },
            {
               value: "K",
               text: "Kids",
            },
         ],
      };
   },
   watch: {
      categoria: function () {
         this.initialize();
      },
      rango: function () {
         this.initialize();
      },
      items: function () {
         let pos = 1;
         let skip = 1;
         let scorePre = this.items[0]?.score;
         for (let i = 1; i < this.items.length; i++) {
            if (this.items[i].score !== scorePre) {
               pos = pos + skip;
               scorePre = this.items[i].score;
               this.items[i].posicion = pos;
               skip = 1;
               continue;
            }
            this.items[i].posicion = pos;
            skip++;
         }
      },
   },
   methods: {
      async initialize() {
         try {
            this.isloading = true;
            await this.$store.dispatch("getGenerales");
            let items = this.$store.state.generales

            if(this.categoria){
               items = items.filter(elem => elem.categoria == this.categoria)
            }
            if(this.rango){
               items = items.filter(elem => elem.rango == this.rango)
            }

            let i = 0;
            items.map((elem) => {
               i++;
               elem.posicion = i;
            })

            this.items = items;
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      },
      toExcel() {
         if (this.items && this.items.length > 0) {
            let csv = convertToCSV(this.items);
            fileDownload(csv, `generales ${new Date()}.csv`);
         }
      },
      async send(){
         try {
            if (this.items && this.items.length > 0) {
               this.isloading = true;
               await this.$store.dispatch("sendGenerales", this.items);
               this.$refs.ok_noti.Show();
            }
         } catch (error) {
            this.$refs.error_noti.ShowPersistent(error);
         } finally {
            this.isloading = false;
         }
      }
   },
   created() {
      this.initialize();
   },
};
</script>

<style scoped>
.my-select {
   max-width: 200px;
}
.pretty_title {
   font-size: 9px;
   font-weight: 900;
   text-transform: uppercase;
   letter-spacing: 2px;
}
.pretty_title_2 {
   font-size: 12px;
   font-weight: 800;
   letter-spacing: 2px;
}
</style>
